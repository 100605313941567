import React from 'react'
import { Helmet } from 'react-helmet'
import '../../styles/news.less'
import Logo from '../../images/logo.svg'
import bajutina from '../../images/bajutina.jpg'
import ScrollToTopOnMount from '../../helpers/scrollRestorationFix'

const TITLE = 'Первому ипотечному резиденту Сколково три года'
const DESCRIPTION =
  'Ипотека.Центр стал первым ипотечным проектом в&nbsp;&laquo;Сколково&raquo;'

const ThreeYears = () => {
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
        <meta name="description" content={DESCRIPTION} />
        <meta property="og:description" content={DESCRIPTION} />
      </Helmet>
      <ScrollToTopOnMount />
      <div className="news-layout">
        <header className="news-header">
          <a href="/" target="_blank">
            <img src={Logo} alt="logo" />
          </a>
        </header>
        <article className="news-article">
          <h1>{TITLE}</h1>
          <h3 className="news-article__lead">
            В&nbsp;начале июля компания Ипотека.Центр отметила три года.
            С&nbsp;момента основания стартап запустил собственную платформу
            по&nbsp;цифровизации ипотечного бизнеса, создал новую профессию
            «цифрового агента ипотеки» и&nbsp;стал первым ипотечным резидентом
            «Сколково». За&nbsp;последние полгода пользователями платформы стали
            3&nbsp;092 эксперта ипотеки со&nbsp;всей России и&nbsp;провели почти
            1000 сделок на&nbsp;сумму 4,5 млрд рублей.
          </h3>
          <section className="news-text-section">
            <p style={{ marginBottom: '35px' }} className="news-paragraph">
              14&nbsp;июля 2020, Москва&nbsp;&mdash; Компания Ипотека.Центр
              подвела финансовые итоги полугодия и&nbsp;отметила день рождения.
            </p>
            <p className="news-paragraph">
              Еще три года назад стартап начинал с&nbsp;предоставления услуг
              по&nbsp;сопровождению ипотечного кредитования с&nbsp;офисами
              в&nbsp;Москве и&nbsp;Санкт-Петербурге. А&nbsp;уже в&nbsp;июне 2019
              года команда запустила свой собственный&nbsp;IT
              продукт&nbsp;&mdash; платформу цифрового ипотечного агента, что
              позволило специалистам ипотеки со&nbsp;всей России проводить
              полный цикл сделки онлайн из&nbsp;любой точки мира.
            </p>
            <p className="news-paragraph">
              Разработка представляет собой удаленное рабочее место для ведения
              сделок по&nbsp;ипотеке для клиентов: от&nbsp;подачи заявки
              до&nbsp;регистрации сделки в&nbsp;Росреестре. Используемые
              технологии позволяют в&nbsp;разы сократить время работы
              с&nbsp;ипотечной заявкой и&nbsp;автоматизировать большинство
              рутинных операций (подбор программ, ввод и&nbsp;распознавание
              документов, единая анкета для всех банков). А&nbsp;встроенный
              функционал CRM системы помогает быстро ориентироваться
              в&nbsp;условиях постоянно меняющихся данных и&nbsp;максимально
              оперативно решать вопросы оформления всех необходимых документов
              для клиента, не&nbsp;выходя из&nbsp;личного кабинета.
            </p>
            <p className="news-paragraph">
              В&nbsp;2020 году&nbsp;IT проект был высоко оценен экспертами
              инновационного центра &laquo;Сколково&raquo; и&nbsp;получил статус
              официального резидента. Платформа Ипотека.Центр стала
              не&nbsp;только незаменимым инструментом для работы,
              но&nbsp;и&nbsp;пространством для диалога между участниками всего
              ипотечного рынка.
            </p>
            <p className="news-paragraph">
              За&nbsp;последние полгода пользователями и&nbsp;бизнес-партнёрами
              платформы стали 3092 эксперта ипотеки со&nbsp;всей России, что
              в&nbsp;4 раза больше показателей прошлого года за&nbsp;аналогичный
              период. Особенно активный прирост бизнес-партнеров был
              зафиксирован в&nbsp;период самоизоляции. Всего за&nbsp;первое
              полугодие 2020 года через&nbsp;платформу проведено почти 1000
              сделок на&nbsp;сумму 4,5 млрд рублей.
            </p>
          </section>
          <section className="news-citations">
            <div className="news-citations__item">
              <p>
                Мы&nbsp;собрали команду настоящих профессионалов, которая
                ежедневно работает над улучшением продуктов, отвечает
                на&nbsp;все вызовы ипотечного рынка, оперативно реагирует
                на&nbsp;все запросы наших бизнес-партнёров. В&nbsp;планах
                не&nbsp;останавливаться на&nbsp;достигнутом, наращивать объемы
                бизнеса и&nbsp;продолжать показывать результаты, превышающие
                ожидания. Трудности первого полугодия 2020 оказались для
                компании хорошим подспорьем и&nbsp;показали, насколько наш
                продукт опережает время. На&nbsp;нас лежит ответственность
                не&nbsp;потерять заданный темп и&nbsp;продолжить делать лучший
                сервис для агентов ипотеки.
              </p>
              <div className="news-citations__author">
                <img src={bajutina} alt="Ольга Бажутина" />
                <div>
                  <h5>Ольга Бажутина</h5>
                  <span>генеральный директор Ипотека.Центр</span>
                </div>
              </div>
            </div>
          </section>
        </article>
        <footer className="news-footer">
          <h6 className="news-footer__title">Справка</h6>
          <p className="news-paragraph">
            Ипотека.Центр&nbsp;&mdash; ипотечная&nbsp;IT компания, находящаяся
            на&nbsp;стыке облачных технологий и&nbsp;реального бизнеса. Компания
            берёт своё начало с&nbsp;августа 2017&nbsp;года. Является ТМ ООО
            &laquo;ФинМаркет&raquo;{' '}
            <a href="/" target="_blank">
              https://ipoteka.center
            </a>
          </p>
          <p className="news-paragraph">
            Платформа Ипотека.Центр&nbsp;&mdash; собственная российская
            разработка ООО &laquo;ФинМаркет&raquo;. Инновационный сервис для
            профессионалов ипотечного бизнеса, позволяет проводить полный цикл
            сделки по&nbsp;ипотечному кредиту онлайн и&nbsp;получать высокое
            агентское вознаграждение. Платформа была запущена в&nbsp;июне
            2019&nbsp;года.
          </p>
          <p className="news-paragraph">
            Контакты для&nbsp;прессы:{' '}
            <a href="mailto:pr@ipoteka.center">pr@ipoteka.center</a>
          </p>
        </footer>
      </div>
    </>
  )
}

export default ThreeYears
